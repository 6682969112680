<template>
  <div
    :style="{
      'background-image': 'url(' + banner.image.source + ')',
    }"
    :class="[
      'abnner',
      { 'abnner--right': banner.content_position === 'right' },
    ]"
  >
    <div :style="{ color: banner.text_color }" class="abnner__content">
      <div
        v-if="banner.title"
        class="abnner__title h3"
        v-html="banner.title"
      ></div>
      <div
        v-if="banner.description"
        class="abnner__description"
        v-html="banner.description"
      ></div>
      <div
        v-if="banner.agreement_text"
        class="abnner__description"
        v-html="banner.agreement_text"
      ></div>
      <div class="abnner__btn">
        <button
          type="button"
          class="btn btn-white btn-small stretched-link"
          data-popup-with-chats
          :data-popup-with-chats-title="
            banner.text_button || 'Узнать подробнее'
          "
          @click="$emit('click', $event, banner)"
        >
          {{ banner.text_button || "Узнать подробнее" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
  emits: ["click"],
};
</script>

<style scoped>
.abnner {
  padding: 0px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.abnner--right {
  text-align: right;
}
.abnner__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 40px 40px 39%;
  min-height: 224px;
  color: #fff;
  font-weight: 300;
}
.abnner__title {
  margin: 0px 0px 4px 0px;
  font-size: 36px;
}
.abnner__title:last-child {
  margin-bottom: 0;
}
@media (max-width: 1279px) {
  .abnner__title {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .abnner__title {
    line-height: 0.9;
    margin: 0px 0px 8px 0px;
  }
}
.abnner__btn {
  margin-top: 17px;
}
@media (max-width: 991px) {
  .abnner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .abnner__title {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .abnner__btn .btn {
    padding: 10px 20px 12px;
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .abnner {
    background-position: 100% 50%;
    background-size: auto 2000px;
  }
  .abnner:before {
    display: none;
  }
  .abnner__content {
    padding: 30px;
  }
  .abnner__title {
    font-size: 20px;
  }
}
</style>
