<template>
  <div
    class="abnner"
    :style="{
      'background-image': 'url(' + banner.image.source + ')',
    }"
  >
    <div :style="{ color: banner.text_color }" class="abnner__content">
      <div
        v-if="banner.title"
        class="abnner__title h3"
        v-html="banner.title"
      ></div>
      <div
        v-if="banner.description"
        class="abnner__description"
        v-html="banner.description"
      ></div>
      <div class="abnner__btn">
        <button
          type="button"
          class="btn btn-white btn-small stretched-link"
          data-popup-with-chats
          :data-popup-with-chats-title="banner.text_button || 'Узнать больше'"
          @click="$emit('click', $event, banner)"
        >
          {{ banner.text_button || "Узнать больше" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
  emits: ["click"],
};
</script>

<style scoped>
.abnner {
  padding: 0px;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #205640;
}
.abnner__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 40px 40px 35%;
  color: #fff;
  font-weight: 300;
  text-align: right;
}
.abnner__title {
  margin: 0px 0px 4px 0px;
}
.abnner__title:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .abnner__title {
    line-height: 0.9;
    margin: 0px 0px 8px 0px;
  }
}
.abnner__btn {
  margin-top: 17px;
}
@media (min-width: 1280px) {
  .abnner__btn .btn {
    padding: 12px 32px 15px;
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .abnner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 86, 64, 0.4);
  }
}
@media (max-width: 767px) {
  .abnner__btn .btn {
    padding: 10px 20px 12px;
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .abnner__content {
    padding: 30px;
  }
}
</style>
