<template>
  <section class="home-block">
    <div class="container">
      <div ref="title" class="h2 title">Мы всегда готовы&nbsp;помочь</div>
      <div ref="list" class="list">
        <swiper
          class="hasPagination"
          navigation
          loop
          :pagination="{ clickable: true }"
          :preload-images="false"
          :slides-per-view="'auto'"
          :space-between="24"
          :centered-slides="true"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="item in list" :key="item.title">
            <div class="item row no-gutters">
              <div class="col-sm-6">
                <div v-if="item.img" class="item__image">
                  <img
                    :src="item.img"
                    width="300"
                    height="360"
                    loading="lazy"
                    :alt="item.title"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="item__content d-flex flex-column">
                  <div class="item__title h4" v-html="item.title"></div>
                  <div class="item__text mt-auto" v-html="item.who"></div>
                  <div class="item__cta">
                    <a
                      href="javascript:void(0)"
                      data-popup-with-chats
                      data-popup-with-chats-title="Связаться со специалистом"
                      class="d-inline-flex"
                      ><span>Связаться</span><i>&rarr;</i></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      list: [
        {
          title: "Агакишиев<br> Александр",
          img: require('@/assets/i/html/team/team-1.jpg'),
          who: "Эксперт по&nbsp;продажам",
        },
        {
          title: "Страхова<br> Анна",
          img: require('@/assets/i/html/team/team-4.jpg'),
          who: "Эксперт по&nbsp;ипотеке",
        },
        {
          title: "Виталий<br>Никитин",
          img: require('@/assets/i/html/team/team-6.jpg'),
          who: "Эксперт по&nbsp;продажам",
        },
        {
          title: "Засимова<br>Юлия",
          img: require('@/assets/i/html/team/team-7.jpg'),
          who: "Эксперт по&nbsp;продажам",
        },
      ],
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      if (!is_touch()) {
        this.$nextTick(() => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: this.$el,
                start: "top 90%",
                end: "bottom bottom",
                scrub: 1.5,
              },
            })
            .from(
              gsap.utils.toArray([this.$refs.title, this.$refs.list]),
              {
                y: 150,
                stagger: { each: 0.1 },
              },
              0
            );
        });
      }
    },
  },
};
</script>

<style scoped>
.home-block {
  background: #f9f9f9;
}
.home-block.mt {
  margin-top: 110px;
}
@media (max-width: 767px) {
  .home-block.mt {
    margin-top: 65px;
  }
}
.title {
  margin-bottom: 80px;
  text-align: center;
}
@media (max-width: 1279px) {
  .title {
    margin-bottom: 65px;
  }
}
@media (max-width: 991px) {
  .title {
    margin-bottom: 40px;
  }
}
.item {
  min-height: 360px;
  background: #fff;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.05);
}
.list .swiper-container {
  overflow: inherit;
}
.list :deep(.swiper-slide) {
  width: 600px;
}
@media (max-width: 767px) {
  .list :deep(.swiper-slide) {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }
}
.item__image {
  height: 100%;
}
.item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item__content {
  height: 100%;
  padding: 25px;
}
.item__title {
  line-height: 1.1111;
  margin-bottom: 20px;
}
.item__cta {
  font-weight: normal;
  font-size: 20px;
  margin-top: 14px;
}
.item__cta a {
  text-decoration: none;
}
body.-notouch .item__cta a:hover span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.item__cta i {
  font-style: normal;
  margin-left: 20px;
  transition: transform 0.15s;
}
body.-notouch .item__cta a:hover i {
  transform: translateX(8px);
}
</style>
